// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
// Component Modules
import { SidebarModule } from './sidebar/sidebar.module';
import { GridModule } from './grid/grid.module';
import { ProjectModule } from './project/project.module';
import { HomeModule } from './home/home.module';
import { AboutModule } from './about/about.module';
// Components
import { AppComponent } from './app.component';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    LayoutModule,
    SidebarModule,
    GridModule,
    ProjectModule,
    HomeModule,
    AboutModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
