import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit { 
  constructor () { }

  @Input() project: string;

  ngOnInit(): void { }
}
