<main>
    <section class="card">
        <section class="header">
            <section class="header-image">
                <section class="background"></section>
            </section>
            <h3>Hallo!</h3>
            <p>My name is <b>Anouska de Graaf</b>. I'm a Full-stack Software Engineer and UX Designer currently 
                working at Rijkswaterstaat in Delft, the Netherlands. I've been working as a Consultant 
                since 2015, mostly focussing on UX design but recently moved more towards front-end and back-end
                development in roles that combine both skills.<br />
                As a consultant, I've had the opportunity to work for dozens of different clients, on projects
                ranging from short 2 days assignments to longterm projects of over a year, and anything
                in between. For case studies of some of these projects have a look at my <a [routerLink]="['/work']">work</a>!</p>
                <h3>Some of the companies I have worked with:</h3>
                <section class="clients">
                    <img src="../../assets/images/about/shell.png" alt="Logo Shell" />
                    <img src="../../assets/images/about/fugro.png" alt="Logo Fugro" />
                    <img src="../../assets/images/about/arcadis.png" alt="Logo Arcadis" />
                    <img src="../../assets/images/about/atradius.png" alt="Logo Atradius" />
                    <img src="../../assets/images/about/rabobank.png" alt="Logo Rabobank" />
                    <img src="../../assets/images/about/mondelez.png" alt="Logo Mondelez" />
                </section>
        </section>
        <section class="experience">
          <hr />
          <h3 class="work">Experience</h3>
          <ul>
              <li><b>Rijkswaterstaat:</b> Full-stack Software Engineer, 2021 - now</li>
              <li><b>ALTEN:</b> UX Consultant, 2018 - 2021</li>
              <li><b>IBM:</b> UX Consultant, 2015 - 2018</li>
              <li><b>STRP:</b> Transformer (volunteer), 2015</li>
              <li><b>FourceLabs:</b> Internship, 2013</li>
          </ul>
          <hr />
          <h3 class="education">Education</h3>
          <ul>
              <li><b>B ICT degree</b> in Communication & Multimedia Design at Avans University of Applied Sciences, 2010 - 2014</li>
              <!-- <li><b>VWO</b> E&M at Graaf Engelbrecht College, 2004 - 2010</li> -->
          </ul>
          <!-- <h3 class="courses">Courses</h3>
          <ul>
              <li><b>Praktische Psychologie</b> at NHA, 2019</li>
              <li><b>Being a UX Leader:</b> Essential Skills for Any UX Practitioner at Nielsen Norman Group, 2019</li>
              <li><b>Jumping to Excellence</b> at Freudenberg, 2019</li>
              <li><b>IBM Design Thinking Co-Creator</b> at IBM, 2017</li>
              <li><b>iOS Design Foundation</b> at IBM, 2017</li>
              <li><b>JavaScript Basis met AJAX, JSON en JQuery</b> at Springest, 2016</li>
              <li><b>Certified ScrumMaster&reg;</b> at Scrum Alliance, 2015</li>
              <li><b>IBM Design Thinking Practitioner</b> at IBM, 2015</li>
              <li><b>AEM Sites: Developer | IBM</b> at Adobe, 2015</li>
              <li><b>AEM Sites: Author | IBM</b> at Adobe, 2015</li>
          </ul> -->
        </section>
        <section class="skills">
          <hr />
          <h3 class="skills">Skills</h3>
          <div class="chart">
              <div class="chart-row chart-period">
                  <span>2004</span><span>...</span><span>2010</span>
                  <span>2011</span><span>...</span><span>2014</span>
                  <span>2015</span><span>2016</span><span>2017</span>
                  <span>2018</span><span>...</span><span>2021</span>
                  <span>Now</span>
              </div>
              <div class="chart-row chart-lines">
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
                  <span></span>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-one">UX Design</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-two">HTML & CSS</li>      
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-three">JQuery</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-four">JavaScript & SCSS</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-five">Angular & TypeScript</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-six">Wordpress</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-seven">PHP</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-eighth">Java</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-nineth">React</li>
                </ul>
              </div>
          </div>
          <div class="chart small">
              <div class="chart-row chart-period">
                  <span>'04</span><span>...</span><span>'10</span>
                  <span>'11</span><span>...</span><span>'16</span>
                  <span>'17</span><span>'18</span><span>Now</span>
              </div>
              <div class="chart-row chart-lines">
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-one">UX Design</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-two">HTML & CSS</li>      
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-three">JQuery</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-four">JavaScript & SCSS</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-five">Angular & TypeScript</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-six">WordPress</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-seven">PHP</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-eighth">Java</li>
                </ul>
              </div>
              <div class="chart-row">
                <ul class="chart-row-bars">
                  <li class="chart-li-nineth">React</li>
                </ul>
              </div>
          </div>
        </section>
    </section>
</main>