import { DomSanitizer } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Project } from './project.model';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent { 

  public projectName: string;
  public project: Project;
  public fullWidth: boolean;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe( params => this.projectName = params.project);
  }

  ngOnInit () {
    this.fullWidth = this.projectName === 'avans' ? true : false;
    this.httpService.get('../assets/data/' + this.projectName + '.json').subscribe(
      data => {
        this.project = data[0] as Project;
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  public isString(val): boolean { return typeof val === 'string'; }

  public safeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
