import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'card-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent { 
  constructor (private httpService: HttpClient) {}
  public projects: string[];

  ngOnInit () {
    this.httpService.get('../assets/data/projects.json').subscribe(
      data => {
        this.projects = data as string [];
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }
}
