<main *ngIf="project">
    <section class="card" [ngClass]="fullWidth ? 'full-width' : ''">
        <section class="header">
            <div class="project-logo" [ngClass]="project.header.logoBg ? project.header.logoBg : ''">
                <img src="{{project.header.logo}}" alt="logo" />
            </div>
            <div class="project-caption">
                <h1><b>{{project.header.company}}</b> &middot; {{project.header.job}}</h1>
                <h2>{{project.header.date}}</h2>
            </div>
        </section>
        <article class="project">
            <img *ngIf="project.general.img" src="{{project.general.img}}" class="{{project.general.imgClass}}" alt="{{project.general.imgAlt}}" />
            <div *ngIf="project.general.video" class="videoWrapper">
                <iframe width="100%" frameborder="0" title="{{project.general.videoAlt}}" [src]="safeUrl(project.general.video)" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <section>
                <div class="column-3-1">
                    <div>
                        <h3>Overview</h3>
                        <p>{{project.general.description}}</p>
                    </div>
                    <div>
                        <h3>Key Roles</h3>
                        <ul *ngFor="let role of project.general.roles">
                            <li>{{role}}</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <ng-container *ngFor="let project of project.projects">
                <section *ngIf="project.type === 'current'">
                    <hr />
                    <h3>{{project.title}}</h3>
                    <h1>{{project.company}}</h1>
                    <div class="column-2-2">
                        <div>
                            <img src="{{project.content.img.src}}" width="{{project.content.img.width}}" alt="{{project.content.img.alt}}" />
                        </div>
                        <div>
                            <p>{{project.content.p}}</p>
                        </div>
                    </div>
                </section>
                <section *ngIf="project.type !== 'current'">
                    <hr />
                    <h3>{{project.title}}</h3>
                    <h1>{{project.company}}</h1>
                    <p [ngClass]="project.class ? project.class : ''">{{project.description}}</p>
                    <img *ngIf="project.img" src="{{project.img.src}}" alt="{{project.img.alt}}" />
                    <div *ngIf="project.video" class="videoWrapper">
                        <iframe width="100%" frameborder="0" title="{{project.video.alt}}" [src]="safeUrl(project.video.src)" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                    </div>

                    <ng-container *ngFor="let section of project.sections">
                        <img *ngIf="section.type === 'image'" [ngClass]="section.class ? section.class : ''" src="{{section.img}}" alt="{{section.imgAlt}}" />

                        <div *ngIf="section.type !== 'image'" class="column-1-3">
                            <h2 *ngIf="section.title !== ''">{{section.title}}</h2>
                            <div>
                                <ng-container *ngIf="section.type === 'paragraph'">
                                    <p *ngFor="let p of section.p">{{p}}</p>
                                </ng-container>

                                <ul *ngIf="section.type === 'list'" class="styled-list">
                                    <ng-container *ngFor="let item of section.ul">
                                        <li *ngIf="isString(item)">{{item}}</li>
                                        <ul *ngIf="!isString(item)" class="styled-list">
                                            <li>{{item}}</li>
                                        </ul>
                                    </ng-container>
                                </ul>

                                <ng-container *ngIf="section.type === 'subtitle'">
                                    <p>{{section.p[0]}}</p>
                                    <h4>{{section.subtitle}}</h4>
                                    <p>{{section.p[1]}}</p>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </ng-container>
        </article>
    </section>
</main>