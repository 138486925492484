import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public isSmallScreen: boolean;
  public isMenuOpen: boolean = false;
  constructor( public breakpointObserver: BreakpointObserver ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 850px)'])
      .subscribe((state: BreakpointState) => {
        this.isSmallScreen = state.matches;
      });
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}