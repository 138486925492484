import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GridComponent } from './grid.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { GridRoutingModule } from './grid-routing.module';

@NgModule({
  declarations: [
    GridComponent,
    ProjectCardComponent
  ],
  imports: [
    CommonModule,
    GridRoutingModule,
    RouterModule
  ],
  providers: [],
  exports: [
    GridComponent,
    ProjectCardComponent
  ]
})
export class GridModule { }
