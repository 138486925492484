<article *ngIf="project">
  <a [routerLink]="['/work', project.name]">
  <section class="logo" style.background="{{project.background}}">
      <img src="{{project.logo}}" alt="Logo {{project.company}}" />
    </section>
    <section class="caption">
      <h1>{{project.company}}</h1>
      <h3 *ngIf="project.via">via <img src="{{project.via}}" alt="Logo Alten" /></h3>
      <h2>{{project.date}}</h2>
    </section>
  </a>
</article>